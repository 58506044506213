.irregular-btn, .irregular-h1 {
  font-family: 'amaticbold';
  background-repeat: no-repeat;
  box-shadow: none;
  color: #fff;
  padding: 0;
  display: inline-block;
  position: relative;
  & span {
    margin: 0.7rem 3rem 1rem 3rem;
    display: inline-block;
  }
  & svg {
    position: absolute;
    top: 0;
    z-index: -1;
  }
}

.irregular-btn {
  &:hover {

  }
}

.irregular-h1 {
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  & svg {
    width: 12rem;
    z-index: 3;
    left: -0.5rem;
    top: 1rem;
  }
  & span {
    position: relative;
    z-index: 4;
    margin-bottom: -1rem;
    margin-top: 1.6rem;
    font-size: 2.4rem;
  }
}