.national {
  visibility: hidden;
  & .irregular-h1 {
    height: 5rem;
    & svg {
      width: 14rem;
      & path {
        fill: #0051AA;
      }
    }
    & span {
      margin: 2rem 1rem !important;
    }
  }
}

.product-box {
  text-align: center;
}

.product-box{
  padding-right: 5% !important;
  padding-left: 5% !important;
  margin-bottom: 2rem;
  & .irregular-btn span {
    font-size: 2rem;
    margin-top: 0rem;
  }
  & a {
    transition: 0.3s;
    &:hover {
      transform: rotate(4deg);
    }
  }
}

.product {
  visibility: hidden;
  padding-top: 3rem;
  & .irregular-h1 {
    text-align: center;
  }
  & .irregular-h1 svg {
    left: 50%;
    margin-left: -90px;
    width: 180px;
  }
}

.product-img, .fast-info {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.fast-info svg {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
}

.chia .irregular-btn svg path, .chia .irregular-h1 svg path, .chia svg .seed-color {
  fill: #e2231a;
}
.linen .irregular-btn svg path, .linen .irregular-h1 svg path, .linen svg .seed-color {
  fill: #9e1a96;
}
.sunflower .irregular-btn svg path, .sunflower .irregular-h1 svg path, .sunflower svg .seed-color {
  fill: #019887;
}
.quinoa .irregular-btn svg path, .quinoa .irregular-h1 svg path, .quinoa svg .seed-color {
  fill: #94d500;
}
.sesame .irregular-btn svg path, .sesame .irregular-h1 svg path, .sesame svg .seed-color {
  fill: #0055b7;
}
.pumpkin .irregular-btn svg path, .pumpkin .irregular-h1 svg path, .pumpkin svg .seed-color {
  fill: #ffa300;
}
.multifloral .irregular-btn svg path, .multifloral .irregular-h1 svg path, .multifloral svg .honey-color {
  fill: #fbaf17;
  /*fill: red;*/
}
.organic .irregular-btn svg path, .organic .irregular-h1 svg path, .organic svg .honey-color {
  fill: #91c73d;
  /*fill: blue;*/
}

.nutritional-facts {
  border-left: 1px solid #dfdfdf;
  & h2 {
    font-size: 1rem;
    font-family: Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
  }
  & li:first-child {
    font-weight:700;
  }
  & li {
    margin-bottom: 0.8rem;
  }
  & td {
    padding: 4px;
    font-size: 0.7rem;
  } 
  & th {
    padding: 4px;
    font-size: 0.7rem;
  }
}