nav {
  background-color: transparent !important;
  box-shadow: none;
  text-transform: uppercase;
  text-align: center;
  height: 8rem;
  & a {
    color: #000;
  }
  & ul a {
    color: #000;
    font-size: 1.6rem;
    padding-left: 1rem; 
    padding-right: 1rem;
    border-right: 1px solid #000;
    height: 2rem;
    line-height: 2rem;
    margin-top: 3rem;
    &:hover {
      background-color: transparent;
    }
  }
  & ul li:nth-child(5) a {
    border: 0;
  }
  & ul li:last-child {
    padding: 0 0.4rem 0 0.4rem;
  }
  & .lang {
    float: right;
  }
  & .lang a {
    float: right;
    border-right: 0;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    margin-top: 3rem;
    & svg {
      width: 2rem;
    }
  }
  & ul {
    margin:0 auto;
    display: inline-block;
  }
  & .top-logo {
    float: left;
    background-color: #211915;
    padding: 1rem 0.6rem 0.6rem 0.6rem;
  }
  & svg {
    width: 7rem;
    display: block;
  }

  & .irregular-btn {
    color: #000;
    & span {
      margin-right: 2.6rem;
      margin-left: 2.6rem;
      margin-top: 0;
    }
    & svg {
      display: none;
      width: 90%;
      margin-right: 5%;
      margin-left: 5%;
    }
    &:hover, &.active-btn {
      color: #fff;
      & svg {
        display: inline-block;
      }
    }
  }
  & ul li:nth-child(1) .irregular-btn svg{
    & path {
      fill: #019887;
    }
  }
  & ul li:nth-child(2) .irregular-btn svg{
    margin-top: -2%;
    & path {
      fill: #FEA500;
    }
  }
  & ul li:nth-child(3) .irregular-btn svg{
    margin-top: -7%;
    & path {
      fill: #FF1713;
    }
  }
  & ul li:nth-child(4) .irregular-btn svg{
    margin-top: -6%;
    & path {
      fill: #0051AA;
    }
  }
  & ul li:nth-child(5) .irregular-btn svg{
    margin-top: -3%;
  }
}

.button-collapse {
  margin-top: 3rem;
}
.side-nav {
  & a {
    display: inline-block;
    margin-top: 0;
    border:0;
  }
  & .leaf {
    margin-top: 0;
  }
}

@media only screen and (max-device-width : 600px) {
  nav .top-logo {
    display: inline-block;
    float: initial;
    background-color: #211915;
    padding: 1rem 0.4rem 0.6rem 0.4rem;
  }
}