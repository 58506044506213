.international {
  visibility: hidden;
  & .irregular-h1 svg {
    width: 16rem;
    & path{
      fill: #FF1713;
    }
  }
  & > .irregular-h1 {
    & span {
      margin: 2rem 0.5rem !important;
    }
  }
  & h2 {
    margin-bottom: 1rem;
    &:before {
      content: "• ";
      font-size: 4.2rem;
      color: #FF1713;
      float: left;
      line-height: 1.8rem;
      margin-right: 0.6rem;
      margin-left: -1.3rem;
    }
  }
}
.export-info {
  & h1 {
    text-align: center;
    color: #FF1713;
    width: 100%;
  }
  & h2 {
    font-size: 2rem;
    margin-bottom: 0.4rem;
  }
  & ul {
    margin-top: 0;
  }
  & ul li {
    padding-left: 0.6rem;
  }
}

.exports {
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  & b {
    text-align:center;
    width: 100%;
  }
  & + p {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
  }
}

.fairs {
  & a {
    text-align: center;
    &:hover svg path {
      fill: #000;
    }
  }
  & h5 {
    position: absolute;
    border: 0;
    margin-top: 7rem;
    width: 10rem;
    margin-left: 2rem;
  }
  & h5 svg {
    width: 10rem !important;
  }
  & svg {
    width: 70%;
    & path {
      fill:#FF1713;
    }
  }
}