  textarea.materialize-textarea:focus:not([readonly]) {
     border-bottom: 1px solid #94D500;
     box-shadow: 0 1px 0 0 #94D500;
  }
  input:not([type]):focus:not([readonly]) + label, input[type=text]:focus:not([readonly]) + label, input[type=password]:focus:not([readonly]) + label, input[type=email]:focus:not([readonly]) + label, input[type=url]:focus:not([readonly]) + label, input[type=time]:focus:not([readonly]) + label, input[type=date]:focus:not([readonly]) + label, input[type=datetime]:focus:not([readonly]) + label, input[type=datetime-local]:focus:not([readonly]) + label, input[type=tel]:focus:not([readonly]) + label, input[type=number]:focus:not([readonly]) + label, input[type=search]:focus:not([readonly]) + label, textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #000;
  }
  textarea {
    min-height: 9.8rem !important;
    /*border: 1px solid #dfdfdf !important;*/
  }
  input:not([type]), input[type="text"], input[type="password"], input[type="email"], input[type="url"], input[type="time"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="tel"], input[type="number"], input[type="search"], textarea.materialize-textarea {
    border-bottom: 1px solid #dfdfdf;
  }

  /* label color */
   .input-field label {
     color: #000;
   }
   /* label focus color */
   .input-field input[type=text]:focus + label, .input-field input[type=email]:focus + label, .input-field textarea:focus + label {
     color: #000;
   }
   /* label underline focus color */
   .input-field input[type=text]:focus, .input-field input[type=email]:focus, .input-field textarea:focus {
     border-bottom: 1px solid #94D500;
     box-shadow: 0 1px 0 0 #94D500;
   }
   /* valid color */
   .input-field input[type=text].valid {
     border-bottom: 1px solid #94D500;
     box-shadow: 0 1px 0 0 #94D500;
   }
   /* invalid color */
   .input-field input[type=email].invalid {
     border-bottom: 1px solid red;
     box-shadow: 0 1px 0 0 red;
   }
   /* icon prefix focus color */
   .input-field .prefix.active {
     color: #94D500;
   }
