html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: #94D500;
  color: #fff;
  text-shadow: none;
}

.fonts-loaded body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

body {
  font-family: Helvetica, Arial, sans-serif;
}

@viewport {
  width: device-width;
}

.container {
  width: 90%;
}

h1, h2, h3 {
  font-family: 'amaticbold';
}

main {
  /*visibility: hidden;*/
  /*display: none;*/
}