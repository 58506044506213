.contact {
  visibility: hidden;
  font-size: 1.2rem;
  & ul {
    & li {
      display: inline-block;
      margin-right: 4rem;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      &:before {
        font-size:2.2rem;
      }
    }
  }
}

.textarea-side {
  text-align: center;
}

.contact-form .irregular-btn span {
  font-size: 1.8rem;
  margin-top: 0.2rem;
}

#map-canvas {
  min-height: 400px;
}



#msg{
  height: 64px;
}

#msg-all{
  display: none;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 20px;
  color: red;
}

.backGload{
  background: url('../media/loading.gif') no-repeat center;
  margin: 2rem 0;
}

.contact-error {
  -webkit-box-shadow: inset 0 0 2px red !important;
  -moz-box-shadow: inset 0 0 2px red !important;
  box-shadow: inset 0 0 2px red !important;
}