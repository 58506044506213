.product-boxes {
  & img {
    width: 100%;
  }
  & a {
    margin-bottom: 0.8rem;
    padding: 0 0.4rem !important;
  }
  & .row {
    margin-bottom: 0;
  }
}
.sesame-logo i {
  background-color: #0056b8;
}
.multifloral-honey-logo i {
  background-color: #fcaf17;
}
.sunflower-logo i {
  background-color: #46a78e;
}
.linen-logo i {
  background-color: #9f1897;
}
.quinoa-logo i {
  background-color: #94d60a;
}
.organic-honey i {
  background-color: #a7bf30;
}
.chia-logo i {
  background-color: #e31d1a;
}
.pumpkin-logo i {
  background-color: #f1a31c;
}
.sesame-logo, .multifloral-honey-logo, .sunflower-logo, .linen-logo, .quinoa-logo, .organic-honey, .chia-logo, .pumpkin-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  & i {
    /*background-color: blue;*/
    height: 100%;
    width: 100%;
  }
  & svg {
    height: 40%;
    width: 90%;
    margin: 26% 5% 0 5%;
  }
}

.first-half, .second-half {
  padding:0 !important;
  display: flex;
  visibility: hidden;

}

.export-markets {
  text-align: center;
  & h1 {
    &:before {
      content: url('../media/location-pin.png');
      display: block;
      height: 45px;
      width: 33px;
      display: inline-block;
      margin-right: 0.6rem;
    }
  }
}
#market-canvas {
  min-height: 400px;
}