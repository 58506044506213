footer {
  background-color: #211915;
  color: #fff;
  & a {
    color: #fff;
    text-transform: uppercase;
  }
  & .container {
    margin-bottom: 0 !important;
  }
}

.footer-nav, .leaf {
  margin-top: 3rem;
  & a:before {
    content: url('../media/hoja.svg');
    height: 1rem;
    width: 1.2rem;
    display: inline-block;
    margin-right: 0.6rem;
    margin-bottom: 1rem;
  }
/*  & a:hover {
    color: #95D600;
  }*/
}

.certifications {
  & h3 {
    text-align: center;
    border-bottom: 1px solid #2f2a28;
    font-size: 1.6rem;
    margin: 2rem 10% 1rem 10%;
    padding-bottom: 0.6rem;
    font-family: Helvetica,Arial,sans-serif;
  }
  & svg {
    margin-top: 1rem;
  }
  & svg path {
    fill: #fff;
  }
  & a:nth-child(1) svg {
    /*margin-top: 0;*/
  }
  & a:nth-child(3) svg {
    /*margin-top: 0;*/
  }
}

.logo-footer {
  & svg {
    margin: 4rem 20%;
  }
}


@media only screen and (max-device-width : 600px) {
  .logo-footer {
    & svg {
      margin: 5rem 30%;
    }
  }
}