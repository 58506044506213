.business {
  visibility: hidden;
  & .irregular-h1 svg path{
    fill: #FEA500;
  }
  & ul li {
    margin-bottom: 1rem;
    &:before {
      content: "• ";
      font-size: 2.2rem;
      color: #FEA500;
      float: left;
      line-height: 1.3rem;
      margin-right: 0.6rem;
      margin-left: -1.3rem;
    }
  }
  & .draw {
    margin-top: 3rem;
  }
}